@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.body {
  overflow-y: auto;

  @media screen and (max-width: 980px) {
    overflow-y: auto;
  }

  .header {
    width: 100%;
    background-color: #341638;
    height: 70px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

    &-content {
      height: 70px!important;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__logo {
        width: 200px;
      }

      &__button {
        background-color: #845ef1;
        width: 129px;
        align-self: center;
        color: #fff;
        border-radius: 50px;
        padding: 10px 30px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s ease-in-out;
        border: none;

        @media screen and (max-width: 980px) {
          align-self: flex-start;
        }

        &:hover {
          background-color: #602fed;
          color: darken(#fff, 3%);
        }
      }
    }
  }
  .container {
    position: relative;
    width: 100%;
    font-family: 'Poppins', sans-serif;

    &-head {
      background: linear-gradient(90deg, #845ef1 6.88%, #272951 97.5%);
      width: 100%;
      position: relative;
      top: -1px;

      &__content {
        max-width: 1440px;
        margin: 0 auto;
        width: 100%;
        padding: 40px 80px 165px;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 980px) {
          flex-direction: column;
        }

        align-items: center;
        justify-content: space-between;

        &-left {
          border-left: 4px solid #8BBE33;
          padding: 0 0 0 28px;
          font-size: 44px;
          color: #fff;
          line-height: 54px;
          font-weight: 400;
          max-width: 450px;
        }

        &-right {
          color: #fff;
          text-align: right;
          font-size: 18px;
          line-height: 27px;
          font-weight: 200;
          max-width: 429px;

          @media screen and (max-width: 980px) {
            text-align: left;
            max-width: 460px;
            margin-top: 25px;
          }
        }
      }
    }

    &-body {
      max-width: 1280px;
      margin: 0 auto;
      width: 100%;
      background-color: #fff;
      border-radius: 30px;
      position: relative;
      top: -135px;
      padding: 33px 40px 40px 40px;
      box-shadow: 0px 0px 30px 0px rgba(84, 36, 90, 0.10);
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: flex-start;

      @media screen and (max-width: 1240px) {
        gap: 20px;
      }

      @media screen and (max-width: 980px) {
        padding: 16px 20px 18px;
        height: fit-content;
        flex-direction: column;
        min-width: 328px!important;
        width: 100%;
      }

      &-title {
        padding: 0;
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #1a1c21;
      }

      &__left {
        width: 100%;
        max-width: 300px;

        @media screen and (max-width: 980px) {
          max-width: 100%;
        }
      }

      &__right {
        width: 100%;

        &-top {
          display: flex;
          flex-direction: row;
          gap: 10px;
          margin: 10px 0 0 0;
          width: 100%;

          @media screen and (max-width: 980px) {
            flex-direction: column;
          }

          .config {
            padding: 28px 30px 16px;
            border-radius: 30px;
            border: 1px solid #ececec;
            width: 100%;

            @media screen and (max-width: 1200px) {
              min-width: 290px;
              padding: 14px 16px 8px!important;
            }

            .divider {
              width: 100%;
              height: 1px;
              background-color: #ececec;
            }

            &-title {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
              color: #1a1c21;
              margin: 0;
            }

            &-info {
              display: flex;
              flex-direction: column;

              &-row {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                label {
                  color: #7A7A7A;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }

                &__title {
                  color: #7A7A7A;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;

                  @media screen and (max-width: 980px) {
                    margin-top: 15px;
                  }

                  &.smaller {
                    color: #7A7A7A;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }

                &__value {
                  margin: 0;
                  padding: 0;
                  color: #1A1C21;
                  text-align: right;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }

                input {
                  padding: 16px;
                  border-radius: 8px;
                  border: 1px solid #ececec;
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  color: #1a1c21;
                  font-family: 'Poppins', sans-serif;
                  text-align: right;
                  max-width: 189px;

                  &::placeholder {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: #1a1c21;
                  }
                }
              }

              &-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                label {
                  color: #7A7A7A;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }

                &__title {
                  color: #7A7A7A;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;

                  @media screen and (max-width: 980px) {
                    margin-top: 15px;
                  }

                  &.smaller {
                    color: #7A7A7A;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }

                &__value {
                  margin: 0;
                  padding: 0;
                  color: #1A1C21;
                  text-align: right;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }

                input {
                  padding: 16px;
                  border-radius: 8px;
                  border: 1px solid #ececec;
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  color: #1a1c21;
                  font-family: 'Poppins', sans-serif;
                  text-align: right;
                  max-width: 189px;

                  &::placeholder {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: #1a1c21;
                  }
                }
              }
            }

            &-percent {
              margin: 10px 0 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 30px;

              @media screen and (max-width: 980px) {
                justify-content: space-around;
                gap: 15px;
              }
              &__header {
                font-weight: 600;
                font-size: 16px;
                line-height: 12px;
                margin-bottom: 16px;
                color: #1A1C21;
              }
              &__items {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                flex-wrap: wrap;
                gap: 5px;
                width: 100%;

                @media (max-width: 615px) {
                  justify-content: flex-start;
                  gap: 15px;
                  width: 80%;
                  margin: 0 auto;
                }

                &__item {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;

                  &-value {
                    margin: 0;
                    padding: 0;
                    color: #1A1C21;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                    font-family: "Source Code Pro", monospace;
                    text-align: left;

                    span {
                      font-size: 24px;
                      line-height: 1.5;
                    }
                  }
                }
              }

            }
          }

          .costs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;



            &-bottom {
              height: 100%;
              width: 280px;
              border: 1px solid #ececec;
              border-radius: 30px;
              padding: 28px 30px 16px;
              display: flex;
              flex-direction: column;
              text-align: center;

              @media screen and (max-width: 1200px) {
                width: 100%;
                min-width: 290px;
              }

              &__title {
                color: #1A1C21;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                margin-top: 0;
                margin-bottom: 35px;
                text-align: left;

                &__small {
                  text-align: left;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  margin: 0;
                }
              }

              &__value {
                padding: 0;
                text-align: left;
                color: #1A1C21;
                font-size: 44px;
                font-weight: 700;
                line-height: 66px;
                margin: 0;

                span {
                  color: #1A1C21;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 1.5;
                }

                &__small {
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 45px;
                  text-align: left;
                  margin: 0 0 25px 0;

                  span {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }

        &-bottom {
          padding: 25px 20px 30px 30px;
          display: flex;
          flex-direction: column;
          gap: 14px;
          width: 100%;
          border: 1px solid #ececec;
          border-radius: 30px;

          @media screen and (max-width: 980px) {
            min-width: 290px;
          }

          &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .title {
              margin: 0;
              padding: 0;
              color: #1A1C21;
              font-size: 16px;
              font-weight: 600;
              line-height: 1.5;
            }
          }

          table {
            @media screen and (max-width: 700px) {
              display: flex;
              flex-direction: row;
              gap: 15px;
            }

            thead {
              @media screen and (max-width: 700px) {
                display: flex;
                flex-direction: column;
              }

              th {
                text-align: right;
                color: #7A7A7A;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.5;
                min-width: 90px;
                height: 31px!important;
                padding: 0 0 14px;
                &:first-child {
                  text-align: left;
                }

                @media screen and (max-width: 700px) {
                  text-align: left;
                }
              }
            }

            tbody {
              tr {
                @media screen and (max-width: 700px) {
                  display: flex;
                  flex-direction: column;
                }

                td {
                  @media screen and (max-width: 550px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 250px
                  }

                  @media screen and (max-width: 450px) {
                    width: 200px
                  }

                  @media screen and (max-width: 400px) {
                    width: 150px
                  }

                  &:first-child {
                    td {
                      padding: 0 0 10px;
                      width: 57%;
                    }
                  }
                  color: #1A1C21;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1.5;

                  &.price {
                    font-weight: 600;
                    width: 111px;
                    text-align: right;

                    .noWrap {
                      display: flex;
                      align-items: center;
                    }

                    .trigger {
                      width: fit-content!important;
                    }

                    .pointer {
                      cursor: pointer;
                      text-decoration: underline #1A1C21;
                      transition: all 0.2s ease-in-out;

                      &:hover {
                        text-decoration: underline #845ef1;
                        color: #845ef1;
                      }
                    }

                    span {
                      color: #8BBE33;
                      font-size: 8px;
                      font-weight: 600;
                      line-height: 12px;
                      vertical-align: middle;
                      margin: 0 0 0 7px;
                    }
                  }
                }

                &:not(:last-of-type) {
                  td {
                    padding: 0 0 14px;
                  }
                }
                @media screen and (max-width: 700px) {
                  &:last-of-type {
                    td {
                      padding: 0 0 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &__left,
      &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &-field {
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          align-items: flex-start;

          > div {
            width: 100%;

            > input {
              height: 53px;
              width: 300px;

              @media screen and (max-width: 980px) {
                width: 100%;
              }

            }
          }


          label {
            font-size: 10px;
            line-height: 21px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            color: #1A1C21;
            span {
              color: #1A1C21!important;
            }

            div {
              color: #1A1C21!important;
            }
          }

          > div > div > div > div > svg {
            fill: #ececec!important;
          }

          > div > div {
            border-bottom: 1px solid #ececec!important;
            color: #1A1C21;
          }

          input {
            padding: 16px;
            border-radius: 8px;
            border: 1px solid #ececec;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #1a1c21;
            font-family: 'Poppins', sans-serif;

            &::placeholder {
              font-size: 14px;
              line-height: 21px;
              font-weight: 400;
              color: #1a1c21;
            }
          }

          // dlya togo shob bula vysota yak na disigne

          .instance {
            line-height: 145px;
          }
        }
      }

      &__divider {
        //height: 721px;
        width: 1px;
        background-color: #ececec;
        position: relative;
        top: -33px;

        @media screen and (max-width: 980px) {
          margin-top: 20px;
          top: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
}

.email {
  color: #845ef1;
}

.error {
  > div {
    height: 200px;
    border: 1px solid #F8A8A8!important;
    border-radius: 30px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
  }
}

.red {
  color: #BE3333!important;
}

.title {
  margin: 0 0 0 5px;
  padding: 0;
  color: #7A7A7A;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  &.smaller {
    color: #7A7A7A;
    font-size: 9px;
    font-weight: 400;
    line-height: 18px;
  }
}

.phone-table {
  display: none;

  @media screen and (max-width: 700px) {
    display: table;
  }
}

.advParameters {
  background: none;
  border: none;
  outline: none;

  margin-top: 10px;

  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.subLabel-field {
  display: flex;
  align-items: center;
  @media screen and (max-width: 980px) {
    width: 100%!important;
  }
  > div > div {
    width: 100%;

    > input {
      height: 53px;
      flex: 1;
      width: 250px!important;

      @media screen and (max-width: 980px) {
        width: 100%!important;
      }

    }
  }

  .subLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #7A7A7A;
    width: 40px;
    margin-left: 9px;
    transform: translateY(16px);

  }
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 15px;
}