.impact-detail-item-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    display: flex;
    flex-direction: row;
  }

  &__title {
    &:last-child {
      width: 100%;
    }
  }

  .impact-detail-item-container__value {
    .impact-detail-table {
      &-title {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
      }
      &-header {
        width: 105px;
        min-width: 105px;
        color: #7A7A7A;
        font-size: 10px;
        font-weight: 400;
        line-height: 1.5;
      }

      &-content {
        display: flex;
        flex-direction: column;
        font-family: "Source Code Pro", monospace;
        font-weight: 600;
        line-height: 16.34px;
        width: 65px;
        text-align: right;
      }
    }

    span {
      color: #8BBE33;
      font-size: 8px;
      font-weight: 600;
      line-height: 12px;
      vertical-align: middle;
      margin: 0 0 0 7px;
    }
  }
}