@import "node_modules/@acc/styles/src/index";

html, body {
  height: 100%;
  width: 100%;
}

#root {
  //max-height:950px!important;
  display: flex;
  //flex: 1 1 auto;
}

body {
  background: var(--mainBackground);
  margin: 0;
  font-family: var(--font);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(.toastBody) {
  font-family: var(--font);
  word-wrap: anywhere;
}

:global(.Toastify__toast-container) {
  width: 350px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

button {
  outline: 0;
}

h1 {
  font-size: 26px;
  font-weight: 400;
  color: var(--app-darkGrey);
  margin: 0;
}

.not-found-page {
  font-size: 180px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  text-transform: uppercase;
  width: 400px;

  span {
    font-size: 40px;
    font-weight: normal;
  }
}

.log-warn {
  color: #C19B00;
  font-weight: bold;
}

.log-info {
  color: #10A10C;
  font-weight: bold;
}

.log-error {
  color: #C50F1F;
  font-weight: bold;
}

.log-debug {
  color: #0037DA;
  font-weight: bold;
}

.log-trace {
  color: #800080;
  font-weight: bold;
}

.log-space {
  padding-left: 15px;
}