.divider {
  width: 100%;
  height: 1px;
  background-color: #ececec;
  &:last-of-type {
    @media screen and (max-width: 980px) {
      display: none!important;
    }
  }
}

.desktop-details-table, .desktop-table-total {
  display: table;

  @media screen and (max-width: 980px) {
    display: none!important;
  }

}

.mobile-impact-details {
  display: none;

  @media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

}

.table-total-title {
  font-size: 14px!important;;
  font-weight: 600!important;
  line-height: 21px!important;;
}