
.savings-container {
  margin-bottom: 24px;
}

.savings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    margin-bottom: 10px;
    &-description {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &-details {
      width: 320px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: none;
      }
    }

  }

  &:last-child {
    border-bottom: none;
  }

  .description {
    flex: 1;
    margin-right: 20px;

    > div {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #1A1C21;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    p {
      color: #7A7A7A;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      margin: 5px 0 10px 0;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .savings,
    .financial {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span:first-child {
        font-size: 10px;
        color: #1A1C21;
        font-weight: 600;
        line-height: 12px;
        display: none;

        @media (max-width: 768px) {
          display: block;
        }
      }

      span:last-child {
        font-size: 24px;
        color: #1A1C21;
        font-weight: 700;
        line-height: 30.17px;
        width: 140px;
        text-align: right;
        font-family: "Source Code Pro", monospace;

        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 25.14px;
          margin-bottom: 20px;
          text-align: left;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header {
    text-align: center;
    h2 {
      margin-bottom: 10px;
    }
  }

  .savings-item {
    flex-direction: column;
    align-items: flex-start;

    .description {
      width: 100%;
      margin-bottom: 10px;
    }

    .details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .financial {
        padding-right: 20px;
        align-items: flex-end;

        &:last-child {
          text-align: right;
        }
      }

      .savings {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
      }
    }
  }

  .savings, .financial {
    width: 50%;
  }

  .details {
    width: 100%;
  }
}