.spinner {
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: var(--primary);
  border-right-color: var(--primary);
  -webkit-animation: single2 1.3s infinite linear;
  animation: single2 1.3s infinite linear;

  &.small {
    height: 20px;
    width: 20px;
  }

  &.medium {
    height: 40px;
    width: 40px;
  }

  &.large {
    height: 50px;
    width: 50px;
  }
}

@-webkit-keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

@keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}